import React, { CSSProperties, FC } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Colors from "@styles/variables/Colors"

const TextStyled = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 2.2;
  padding: 0;

  strong {
    font-weight: bold;
  }

  //sizes
  &.size-xsmall {
    font-size: 14px;
    line-height: 26px;
  }

  &.size-small {
    font-size: 16px;
    line-height: 42px;

    // ${breakpointsMedia(breakpoints.tabletWide)} {
    //   font-size: 18px;
    //   line-height: 40px;
    // }
  }

  &.size-base {
    font-size: 16px;
    line-height: 42px;

    // ${breakpointsMedia(breakpoints.tabletWide)} {
    //   font-size: 20px;
    //   line-height: 40px;
    // }
  }

  &.size-large {
    font-size: 20px;
    line-height: 40px;

    // ${breakpointsMedia(breakpoints.tabletWide)} {
    //   font-size: 20px;
    //   line-height: 40px;
    // }
  }

  &.size-h1 {
    font-size: 38px;
    line-height: 57px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 58px;
      line-height: 87px;
    }
  }

  &.size-h2 {
    font-size: 28px;
    line-height: 42px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 42px;
      line-height: 63px;
    }
  }

  &.size-h3 {
    font-size: 24px;
    line-height: 36px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 28px;
      line-height: 42px;
    }
  }

  &.size-h4 {
    font-size: 18px;
    line-height: 27px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 23px;
      line-height: 36px;
    }
  }

  &.size-h5 {
    font-size: 18px;
    line-height: 27px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 20px;
      line-height: 30px;
    }
  }

  //weights
  &.weight-100 {
    font-weight: 300;
  }

  &.weight-200 {
    font-weight: 200;
  }

  &.weight-300 {
    font-weight: 300;
  }

  &.weight-400 {
    font-weight: 400;
  }

  &.weight-500 {
    font-weight: 500;
  }

  &.weight-600 {
    font-weight: 600;
  }

  &.weight-700,
  &.weight-bold {
    font-weight: 700;
  }

  //colours
  &.colour-black {
    color: var(--color-text);
  }

  &.colour-gray {
    color: ${Colors.zambezi};
  }

  &.colour-white {
    color: var(--color-text-light);
  }

  &.colour-primary {
    color: var(--color-primary);
  }

  &.colour-secondary {
    color: var(--color-secondary);
  }

  &.colour-tertiary {
    color: var(--color-tertiary);
  }

  &.colour-error {
    color: var(--color-error);
  }

  &.colour-success {
    color: var(--color-success);
  }
`

export const Text = ({
  node = "p",
  size = "base",
  colour = "black",
  weight = "",
  children,
  ...props
}) => {
  return (
    <TextStyled
      as={node}
      className={classNames(
        "heading",
        `colour-${colour}`,
        `weight-${weight}`,
        `size-${size}`
      )}
      {...props}
    >
      {children}
    </TextStyled>
  )
}

Text.propTypes = {
  node: PropTypes.string,
  size: PropTypes.oneOf([
    "xsmall",
    "small",
    "base",
    "large",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
  ]),
  weight: PropTypes.oneOf(["light", "regular", "bold"]),
  colour: PropTypes.oneOf([
    "black",
    "inherit",
    "orange",
    "blue",
    "pink",
    "gray",
  ]),
}

export default Text
