import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import SubMobileMenu from "./SubMobileMenu"
import {
  MobileMenuOverlay,
  MobileMenuStyle,
} from "@styles/organisms/MobileMenu"
import WPMenuItemLink from "@components/ui/menu/WPMenuItemLink"
import { useMobileHeader } from "@components/utils/useMobileHeader"

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
export const MobileMenu = ({ isOpen }) => {
  const [activeSubMenu, setActiveSubMenu] = useState(null)
  const mobileHeader = useMobileHeader()

  const menu = mobileHeader[0]?.menuItems.nodes.filter(
    menuItem => menuItem.parentId === null
  )

  const toggle = key => {
    setActiveSubMenu(activeSubMenu === key ? null : key)
  }

  const overlayClasses = classNames({
    "is-open": isOpen,
  })

  return (
    <MobileMenuOverlay
      id="mobile_overlay_menu_container"
      className={overlayClasses}
    >
      <div className="mobile-container container">
        <div className="mobile-inner">
          <MobileMenuStyle className="mobile-menu">
            {menu.map((menuItem, key) => {
              const isActive = activeSubMenu === key
              const hasChildren = menuItem.childItems.nodes.length !== 0

              const liClasses = classNames({
                "menu-item": true,
                "menu-item-has-children": hasChildren,
                "menu-item-active": isActive,
              })

              return (
                <li key={key} className={liClasses}>
                  {hasChildren ? (
                    <div
                      className="link open-sub-menu"
                      onClick={() => toggle(key)}
                    >
                      {menuItem.label}
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down"
                           className="svg-inline--fa fa-angle-down fa-w-10" role="img"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path fill="currentColor"
  d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
                      </svg>
                    </div>
                  ) : (
                    <WPMenuItemLink {...menuItem} />
                  )}

                  {hasChildren ? (
                    <>
                      <WPMenuItemLink
                        url={menuItem.url}
                        className={classNames({
                          "menu-parent-link": true,
                          show: isActive,
                        })}
                      >
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down"
                             className="svg-inline--fa fa-angle-down fa-w-10" role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                          <path fill="currentColor"
  d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
                        </svg>
                      </WPMenuItemLink>
                      <SubMobileMenu
                        isMenuOpen={isOpen}
                        isOpen={activeSubMenu === key}
                        menuItems={menuItem.childItems.nodes}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </li>
              )
            })}
          </MobileMenuStyle>
        </div>
      </div>
    </MobileMenuOverlay>
  )
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
}

MobileMenu.defaultProps = {
  isOpen: false,
}

export default MobileMenu
