import styled from "styled-components"
import headerVars from "../variables/HeaderVars"

export const MobileMenuStyle = styled.section`
  .menu-item {
    > a,
    > .link {
      font-weight: 300;
    }
  }

  .menu-item-has-children {
    .menu-parent-link {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease-out;
      top: 0;
      right: 0;
      transform: translateX(100%);

      &.show {
        position: relative;
        visibility: visible;
        opacity: 1;

        transform: translateX(0%);

        //transition: opacity 0.5s ease-in;
      }
    }
  }

  .menu-item-has-children {
    &.menu-item-active {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .menu-parent-link {
      }

      > a,
      > .link {
        &.open-sub-menu {
          //transition: all linear 1s;
          //justify-content: space-between;
          font-weight: 600;

          > .svg-inline--fa {
            transform: rotate(180deg);
            transition: all 0.4s ease-in-out;
          }
        }
      }
    }
  }
`
export const MobileMenuOverlay = styled.section`
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  padding-top: ${headerVars.header_height_mobile};

  &::-webkit-scrollbar {
    display: none;
  }

  &.is-open {
    opacity: 1;
    visibility: visible;
  }

  &:not(.is-open) {
    pointer-events: none;
  }

  .mobile-container {
    padding: 0 22px;
    overflow-y: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100%;

    .mobile-inner {
      ul {
        margin: 0;
        padding: 0;
      }

      .mobile-menu {
        list-style: none;
        margin: 0;
        padding: 0;

        .menu-item {
          margin-bottom: 17px;
          width: 100%;
          text-align: left;

          &:last-of-type {
            margin-bottom: 0;
          }

          a,
          .link {
            color: var(--color-text-light);
            font-size: 27px;
            line-height: 55px;
            text-align: left;
            cursor: pointer;
          }

          &.menu-item-has-children {
            position: relative;

            .menu-parent-link {
              background-color: var(--color-text-light);
              color: var(--color-text);
              border-radius: 5px;
              text-decoration: none;
              padding: 10px;
              font-weight: 600;
              border: none;
              font-size: 14px;
              letter-spacing: 0.3px;
              line-height: 20px;

              &:active {
                background-color: var(--color-text);

                i {
                  color: var(--color-text);
                }
              }
            }

            a,
            .link {
              cursor: pointer;

              &.open-sub-menu {
                text-decoration: none;
                display: flex;
                align-items: center;

                > .svg-inline--fa {
                  font-weight: 900;
                  transition: all 0.4s ease-in-out;
                  margin-left: 15px;
                }
              }
            }

            .mobile-submenu {
              display: flex;
              align-items: flex-start;
              justify-content: space-evenly;
              flex-direction: column;

              margin-top: 20px;
              padding-left: 20px;
              list-style: none;
              border-left: 3px solid rgba(255, 221, 0, 0.41);

              position: absolute;
              visibility: hidden;
              opacity: 0;

              width: 100%;

              .mobile-submenu {
                margin-left: 20px;
              }

              &.sub-opened {
                padding-left: 0;
                border-left: 0 solid transparent;
              }

              &.opened {
                position: relative;
                visibility: visible;
                opacity: 1;

                transition: opacity 0.5s ease-in, padding-left 0.5s,
                  border-left 0.5s;
              }

              .menu-item {
                a,
                .link {
                  font-size: 17px;
                  line-height: 17px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  justify-content: flex-start;

                  svg:not(.svg-inline--fa),
                  img {
                    height: 35px;
                    width: 35px;
                    margin-right: 15px;

                    ellipse {
                      transition: fill 0.4s ease;

                      &.cls-1 {
                        fill: var(--color-text-light);
                      }
                    }

                    path {
                      &.cls-2 {
                        fill: var(--color-text-light);
                      }
                    }
                  }
                }

                .menu-parent-link {
                  font-size: 14px;
                  line-height: 20px;
                }

                &.menu-item-has-children .mobile-submenu .menu-item {
                  margin: 0 0 10px 0;

                  a,
                  .link {
                    font-size: 17px;
                    line-height: 22px;
                    cursor: pointer;
                  }

                  .menu-parent-link {
                    font-size: 14px;
                    line-height: 20px;
                  }
                }

                &.menu-item-active {
                  a,
                  .link > svg:not(.svg-inline--fa) ellipse.cls-1 {
                    fill: var(--color-secondary);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
