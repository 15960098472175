import React from "react"
import TrustPilotStars from "../../../assets/images/svg/trust-pilot-stars.inline.svg"
import TrustPilotLogo from "../../../assets/images/svg/trust-pilot-logo.inline.svg"
import styled from "styled-components"

const TrustPilotStyle = styled.div`
  position: relative;
  padding-bottom: 10px;

  .tp-widget-checkoutreviews {
    font-family: "Segoe UI", "HelveticaNeue-Light", "Helvetica Neue Light",
      "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
  }

  .bold-underline,
  .tp-widget-empty-vertical__title,
  .tp-widget-empty-horizontal__title,
  .tp-widget-checkoutreviews b,
  .tp-widget-checkoutreviews span {
    font-family: "Segoe UI", "Helvetica Neue", "Helvetica", "Arial",
      "sans-serif";
    font-weight: 500;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .bold-underline,
    .tp-widget-empty-vertical__title,
    .tp-widget-empty-horizontal__title,
    .tp-widget-checkoutreviews b,
    .tp-widget-checkoutreviews span {
      font-weight: 600;
    }
  }

  .tp-widget-wrapper .hidden {
    display: none;
  }

  .tp-widget-loader,
  .tp-widget-fallback {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .tp-widget-wrapper #fallback-logo {
    display: none;
  }

  .no-svg #fallback-logo {
    display: inline-block;
  }

  .tp-widget-wrapper {
    height: 100%;
    margin: 0 auto;
    max-width: 750px;
    position: relative;
  }

  .tp-stars .tp-star__canvas,
  .tp-stars .tp-star__canvas--half {
    fill: #dcdce6;
  }

  .tp-stars .tp-star__shape {
    fill: #fff;
  }

  .tp-stars--1 .tp-star:nth-of-type(-n + 1) .tp-star__canvas,
  .tp-stars--1 .tp-star:nth-of-type(-n + 1) .tp-star__canvas--half {
    fill: #ff3722;
  }

  .tp-stars--2 .tp-star:nth-of-type(-n + 2) .tp-star__canvas,
  .tp-stars--2 .tp-star:nth-of-type(-n + 2) .tp-star__canvas--half {
    fill: #ff8622;
  }

  .tp-stars--3 .tp-star:nth-of-type(-n + 3) .tp-star__canvas,
  .tp-stars--3 .tp-star:nth-of-type(-n + 3) .tp-star__canvas--half {
    fill: #ffce00;
  }

  .tp-stars--4 .tp-star:nth-of-type(-n + 4) .tp-star__canvas,
  .tp-stars--4 .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
    fill: #73cf11;
  }

  .tp-stars--5 .tp-star:nth-of-type(-n + 5) .tp-star__canvas,
  .tp-stars--5 .tp-star:nth-of-type(-n + 5) .tp-star__canvas--half {
    fill: #00b67a;
  }

  .tp-stars--1--half .tp-star:nth-of-type(-n + 2) .tp-star__canvas--half {
    fill: #ff3722;
  }

  .tp-stars--2--half .tp-star:nth-of-type(-n + 3) .tp-star__canvas--half {
    fill: #ff8622;
  }

  .tp-stars--3--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
    fill: #ffce00;
  }

  .tp-stars--4--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas,
  .tp-stars--4--half .tp-star:nth-of-type(-n + 4) .tp-star__canvas--half {
    fill: #00b67a;
  }

  .tp-stars--4--half .tp-star:nth-of-type(-n + 5) .tp-star__canvas--half {
    fill: #00b67a;
  }

  .tp-widget-loader,
  .tp-widget-fallback {
    max-width: 50%;
    text-align: center;
    width: 400vh;
  }

  .tp-widget-loader svg,
  .tp-widget-fallback svg {
    width: 100%;
  }

  @media screen and (min-width: 400px) {
    .tp-widget-loader,
    .tp-widget-fallback {
      max-width: 200px;
    }
  }

  .tp-widget-loader--loaded {
    opacity: 0;
  }

  .bold-underline {
    border-bottom: 1px solid rgba(25, 25, 25, 0.6);
  }

  .bold-underline:hover {
    border-color: #191919;
  }

  .tp-widget-wrapper svg .tp-logo__text {
    fill: white;
  }

  .tp-widget-wrapper svg {
    display: block;
    width: 100%;
  }

  .tp-widget-wrapper a {
    color: #ffffff;
    text-decoration: none;
  }

  .tp-widget-wrapper a:hover {
    color: #ffffff;
    font-weight: inherit;
  }

  .tp-widget-empty-vertical {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .tp-widget-empty-vertical__title {
    font-size: 18px;
    margin: 0 0 12px;
    text-align: center;
  }

  .tp-widget-empty-vertical__stars {
    margin: 0 0 8px;
    width: 150px;
  }

  .tp-widget-empty-vertical__subtitle-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .tp-widget-empty-vertical__subtitle {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    text-align: center;
  }

  .tp-widget-empty-vertical__logo {
    margin: 0 0 5px 5px;
    width: 90px;
  }

  .tp-widget-empty-horizontal {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 100%;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .tp-widget-empty-horizontal__title {
    font-size: 14px;
    text-align: center;
  }

  .tp-widget-empty-horizontal__logo {
    margin: 0 0 5px 5px;
    width: 90px;
  }

  @media screen and (max-width: 300px) {
    .tp-widget-empty-vertical__subtitle-wrapper {
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .tp-widget-empty-vertical__logo {
      margin: 5px 0 0;
    }

    .tp-widget-empty-horizontal {
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .tp-widget-empty-horizontal__logo {
      margin: 0;
    }
  }

  .tp-widget-wrapper {
    font-size: 0;
    text-align: center;
  }

  .tp-widget-checkoutreviews {
    display: block;
    font-size: 0;
    margin: 0 0 8px;
    text-align: center;
  }

  .tp-widget-checkoutreviews b,
  .tp-widget-checkoutreviews span {
    font-size: 16px;
  }

  .tp-widget-checkoutreviews span {
    font-weight: 400;
  }

  .tp-widget-stars {
    margin: 0 auto 8px;
    width: 135px;
  }

  .tp-widget-readmore {
    display: none;
  }

  .tp-widget-logo {
    margin: 0 auto;
    width: 85px;
  }

  @media screen and (min-width: 200px) {
    .tp-widget-checkoutreviews {
      font-size: 16px;
    }

    .tp-widget-stars {
      margin: 0 auto 10px;
      width: 195px;
    }

    .tp-widget-logo {
      margin: 0 auto;
      width: 115px;
    }
  }

  @media screen and (min-width: 400px) {
    .tp-widget-checkoutreviews,
    .tp-widget-stars {
      display: inline-block;
      vertical-align: middle;
    }

    .tp-widget-checkoutreviews {
      margin: 0 8px 8px 0;
    }

    .tp-widget-stars {
      margin: 0 0 8px;
      width: 145px;
    }
  }

  @media screen and (min-width: 576px) {
    .tp-widget-checkoutreviews {
      margin: 0 16px 0 0;
    }

    .tp-widget-stars {
      margin: 0 12px 0 0;
      width: 145px;
    }

    .tp-widget-payoff {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
    }
  }

  .tp-popup-wrapper {
    position: absolute;
    z-index: 10;
    top: 100%;
    width: 100%;
    border-style: none;
    display: none;
    background-color: white;
    height: auto;
    line-height: 19px;
    margin: 0 auto;
    max-width: 650px;
    padding: 12px 8px;
    text-align: center;
  }

  &:hover .tp-popup-wrapper {
    display: block;
  }

  .tp-popup-wrapper::before {
    background-color: #fff;
    border-right: 1px solid #d6d6d6;
    border-top: 1px solid #d6d6d6;
    content: "";
    display: block;
    height: 16px;
    left: 50%;
    position: absolute;
    top: -13px;
    transform: rotate(-34deg) translateX(-50%) skewX(26deg);
    transform-origin: center;
    width: 16px;
  }

  .message,
  .link {
    color: #4c4d4e;
    display: inline-block;
    font-size: 13px;
    margin: 0 2px;
  }

  .message a,
  .link a {
    color: #00b67a;
    text-decoration: none;
  }

  @media screen and (min-width: 200px) {
    .message,
    .link {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 400px) {
    .tp-widget-wrapper {
      padding: 8px 12px;
    }
  }
`

const TrustPilotWidget = () => {
  return (
    <TrustPilotStyle
      className="trustpilot-widget"
      style={{ width: "400px", maxWidth: "100%" }}
    >
      <div id="tp-widget-wrapper" className="tp-widget-wrapper visible">
        <a
          target="_blank"
          className="tp-widget-checkoutreviews profile-url"
          id="translations-checkoutourreviews"
          rel="noopener noreferrer"
          href="https://uk.trustpilot.com/review/www.passiondigital.co.uk?utm_medium=trustbox&amp;utm_source=TrustBoxBasic&amp;utm_campaign=free"
        >
          Check out our <b>69 </b>
          <span>reviews</span>
        </a>
        <div className="tp-widget-stars">
          <a
            id="tp-widget-stars"
            className="profile-url"
            target="_blank"
            rel="noopener noreferrer"
            href="https://uk.trustpilot.com/review/www.passiondigital.co.uk?utm_medium=trustbox&amp;utm_source=TrustBoxBasic&amp;utm_campaign=free"
          >
            <div className="">
              <div className="tp-stars tp-stars--5">
                <div
                  style={{
                    position: "relative",
                    height: "0",
                    width: "100%",
                    padding: 0,
                    paddingBottom: "18.326693227091635%",
                  }}
                >
                  <TrustPilotStars />
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="tp-widget-payoff">
          <div id="tp-widget-logo" className="tp-widget-logo">
            <a
              id="profileLink"
              className="profile-url"
              target="_blank"
              rel="noopener noreferrer"
              href="https://uk.trustpilot.com/review/www.passiondigital.co.uk?utm_medium=trustbox&amp;utm_source=TrustBoxBasic&amp;utm_campaign=free"
            >
              <div className="">
                <div
                  style={{
                    position: "relative",
                    height: "0",
                    width: "100%",
                    padding: 0,
                    paddingBottom: "24.6031746031746%",
                  }}
                >
                  <TrustPilotLogo />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div id="tp-popup-wrapper" className="tp-popup-wrapper visible">
        <p className="message" id="translations-webuildtrust">
          Helping each other make better choices.
        </p>
        <p className="link">
          <a
            target="_blank"
            id="translations-joinnow"
            rel="noopener noreferrer"
            href="https://uk.trustpilot.com/review/www.passiondigital.co.uk?utm_medium=trustbox&amp;utm_source=TrustBoxBasic_Modal&amp;utm_campaign=free"
          >
            Read and write reviews
          </a>
        </p>
      </div>
    </TrustPilotStyle>
  )
}

export default TrustPilotWidget
