import React from "react"
import styled from "styled-components"

import { HeroStyle } from "@styles/organisms/Hero"
import Button from "@components/ui/buttons/Button"
import { storyblokEditable } from "gatsby-source-storyblok"
import Heading from "@components/ui/Heading"
import ButtonGroup from "@components/ui/ButtonGroup"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"

const SimpleHeroStyled = styled(HeroStyle)`
  width: 100%;
  min-height: 665px;
  background-color: var(--color-primary);
  position: relative;
  padding: 51px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpointsMedia(breakpoints.tablet)} {
    height: 672px;
    min-height: auto;
  }

  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .container {
    position: relative;
    height: 100%;

    ${breakpointsMedia(breakpoints.tabletWide, "max")} {
      padding: 0 27px;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    ${breakpointsMedia(breakpoints.tablet)} {
      justify-content: flex-start;
    }

    .inner {
      z-index: 3;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      max-width: 743px;

      .content {
        padding: 9px;

        h1 {
          text-align: left;
        }

        .btn-container {
          .btn {
            margin: 0 8px;
          }
        }
      }
    }

    .hero-bottom {
      position: absolute;
      bottom: 50px;

      .scroll-to-content {
        margin: 0;
        width: 100%;
        display: block;
        color: white;
        font-size: 26px;
        line-height: 1;
        cursor: pointer;
      }
    }
  }
`

const SimpleHero = ({ blok }) => {
  const imageSrc = blok?.hero_image?.filename
  return (
    <>
      <SimpleHeroStyled {...storyblokEditable(blok)} key={blok._uid}>
        {blok?.hero_image && (
          <div className="background-image overlay">
            <img src={imageSrc} alt={blok?.hero_image.alt} />
          </div>
        )}
        <div className="container">
          <div className="inner">
            <div className="content">
              <Heading weight="bold" color="white" decorated>
                {blok?.title}
              </Heading>

              <div className="btn-container">
                <ButtonGroup>
                  <Button style={{ margin: "7px" }} to="/work-for-us/">
                    Work with us
                  </Button>
                  <Button
                    style={{ margin: "7px" }}
                    to="/case-studies/"
                    variant="text-underline"
                    color="white"
                  >
                    View our case studies
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </SimpleHeroStyled>
    </>
  )
}
export default SimpleHero
