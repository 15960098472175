import React from "react"
import PropTypes from "prop-types"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import { Link } from "gatsby"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { storyblokEditable } from "gatsby-source-storyblok"
import Heading from "@components/ui/Heading"
import Text from "@components/ui/text/text"
import Spacer from "@components/ui/Spacer"

const CaseStudyStyled = styled.div`
  background-color: var(--color-primary);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 485px;

  a {
    display: flex;
    flex-direction: column;
    padding: 37px 25px;
    flex: 1;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      padding: 36px 46px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .meta {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: row;

    .meta-item {
      color: var(--color-text-light);
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }

    .meta-item + .meta-item {
      &:before {
        content: "-";
        display: inline-block;
        font-weight: 500;
        margin: 0 5px;
        color: var(--color-primary);
      }
    }
  }

  .background-image {
    position: absolute;
    inset: 0;
    z-index: 0;

    &.overlay::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .logo {
    width: 255px;
    max-width: 100%;
    padding-bottom: 19px;

    img {
      max-height: 43px;
    }
  }

  .link {
    margin-top: 28px;
    color: var(--color-text-light);
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-decoration: none;

    svg {
      margin-left: 5px;
    }
  }

  .title {
    max-width: 366px;

    &:before {
      content: "";
      background-color: var(--color-primary);
      width: 100%;
      margin-bottom: 14px;

      max-width: 125px;
      height: 2px;
      display: block;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        max-width: 255px;
      }
    }
  }
`

const ContentWrap = styled.div`
  margin-top: auto;
  position: relative;

  .tag-line {
    mark {
      background-color: transparent;
      padding: 0;
      color: var(--color-secondary);
    }
  }
`

const CaseStudy = ({ blok }) => {
  const metaTags = blok.content.services?.map(({ content }) => content.title)

  return (
    <CaseStudyStyled {...storyblokEditable(blok)} key={blok._uid}>
      <Link to={`/${blok.full_slug}`}>
        {blok?.content.feature_image && (
          <div className="background-image overlay">
            <img
              src={blok.content.feature_image.filename}
              alt={blok.content.feature_image.alt}
            />
          </div>
        )}
        <div className="meta">
          {metaTags &&
            metaTags.map(meta => <div className="meta-item">{meta}</div>)}
        </div>
        <ContentWrap>
          {(blok?.content.client?.content.white_logo ||
            blok?.content.client?.content.logo) && (
            <div className="logo">
              <img
                src={
                  blok.content.client.content.white_logo?.filename ||
                  blok.content.client.content.logo?.filename
                }
                alt={
                  blok.content.client.content.white_logo?.filename
                    ? blok.content.client.content.white_logo.alt
                    : blok.content.client.content.logo.alt
                }
              />
            </div>
          )}

          <div className="title">
            {blok.content.tag_line && (
              <Heading node="h3" variant="h3" color="white">
                <span
                  className="tag-line"
                  dangerouslySetInnerHTML={{ __html: blok.content.tag_line }}
                />
              </Heading>
            )}
          </div>

          <div className="link">
            See how we did it{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
            >
              <path
                d="M9.71875 1.125L9.46875 1.34375C9.34375 1.5 9.34375 1.71875 9.46875 1.875L12.0938 4.46875H0.375C0.15625 4.46875 0 4.65625 0 4.84375V5.15625C0 5.375 0.15625 5.53125 0.375 5.53125H12.0938L9.46875 8.15625C9.34375 8.3125 9.34375 8.53125 9.46875 8.6875L9.71875 8.90625C9.84375 9.0625 10.0938 9.0625 10.25 8.90625L13.875 5.28125C14.0312 5.125 14.0312 4.90625 13.875 4.75L10.25 1.125C10.0938 0.96875 9.84375 0.96875 9.71875 1.125Z"
                fill="#9B1D4A"
              />
            </svg>
          </div>
        </ContentWrap>
      </Link>
    </CaseStudyStyled>
  )
}

CaseStudy.propTypes = {}

export default CaseStudy
