import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "../variables/Breakpoint"
import { FontFamily } from "../variables/FontFamily"
import headerVars from "../variables/HeaderVars"

export const HeroStyle = styled.section`
  overflow: hidden;
  --pt: calc(32px + ${headerVars.header_height});

  &.header-offset {
    padding-top: var(--pt);

    .has-sb & {
      padding-top: calc(var(--pt) + var(--simple-banner-height));
    }
  }

  h1 {
    text-align: center;

    .underline {
      background-image: linear-gradient(
        var(--color-tertiary) 0%,
        var(--color-tertiary) 100%
      );
    }
  }

  .scroll-to-content {
    margin: 50px auto;
  }

  .hero-head {
    .breadcrumbs {
      position: absolute;
      left: 15px;
      top: 16px;
      min-height: 42px;
      display: flex;
      align-items: center;

      @media (min-width: ${breakpoints.tabletSmall}) {
        top: 32px;
        min-height: 57px;
      }
    }

    .share {
      display: flex;
      align-items: center;
      z-index: 11;
      margin-left: auto;
      position: absolute;
      right: 15px;
      top: 16px;

      @media (min-width: ${breakpoints.tabletSmall}) {
        top: 32px;
      }

      span {
        font-size: 14px;
        line-height: 44px;
        text-align: right;
        font-weight: 600;
        margin-right: 10px;

        ${breakpointsMedia(breakpoints.tabletWide)} {
          font-size: 17px;
          line-height: 44px;
        }
      }
    }
  }

  .cta {
    margin: 40px auto;
  }

  .breadcrumbs {
    .sep,
    ul li > span {
      color: white;
    }
  }
`

export const HeroStyleConstrained = styled(HeroStyle)`
  background-color: var(--color-bg-shade);
  color: var(--color-text-light);
  font-family: ${FontFamily.Heading};
  position: relative;

  .inner {
    text-align: center;
    position: relative;
  }

  .container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-wrap,
  .pre-title-wrap {
    overflow: hidden;
  }

  .title {
    margin-bottom: 0.3em;
    margin-top: 0;
    overflow: hidden;
  }
`
