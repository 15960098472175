import React, { useEffect, useState } from "react"

import WPMenuItemLink from "@components/ui/menu/WPMenuItemLink"
import ServicesMenu from "@components/ui/menu/ServicesMenu"
import AboutMenu from "@components/ui/menu/AboutMenu"
import { HeaderCTA } from "@styles/organisms/Header"
import Button from "@components/ui/buttons/Button"
import { globalHistory } from "@gatsbyjs/reach-router"
import { useDesktopHeader } from "@components/utils/useDesktopHeader"
import MegaMenu from "@components/ui/menu/MegaMenu"

/* eslint-disable react-hooks/exhaustive-deps */
export const DesktopHeader = () => {
  const [hovered, setHovered] = useState(false)
  const [activeMega, setActiveMega] = useState()
  const [clearActiveMegaTimeOut, setClearActiveMegaTimeOut] = useState()

  const desktopHeader = useDesktopHeader()
  const { wp, mainMenu } = desktopHeader

  const siteDetails = wp.siteDetails
  const menu = mainMenu.nodes[0]?.menuItems.nodes
  const megaMenu = mainMenu.nodes[0]?.megaMenu

  const { generalOptions } = siteDetails
  const { aboutMenu, servicesMenu, ourWorkMenu } = megaMenu

  //clear timeout on mega menu change
  useEffect(() => {
    clearTimeout(clearActiveMegaTimeOut)
  }, [activeMega])

  //close mega menu
  const closeMega = () => {
    setActiveMega(null)
  }

  //clear active mega menu
  const clearActiveMega = (delay = false) => {
    if (activeMega === null) return

    clearTimeout(clearActiveMegaTimeOut)

    setClearActiveMegaTimeOut(
      setTimeout(
        () => {
          closeMega()
        },
        delay ? 1000 : 0
      )
    )
  }

  //close menu on scroll
  useEffect(() => {
    const handleScroll = () => {
      clearActiveMega()
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  //close menu on page change
  useEffect(() => {
    const unListen = globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        closeMega()
      }
    })

    return () => {
      unListen()
    }
  }, [])

  const onHover = () => setHovered(true)

  return (
    <div
      className="navigation"
      onMouseOver={onHover}
      onFocus={onHover}
      role="presentation"
    >
      {menu && (
        <nav id="main-menu" className="main-nav">
          <ul id="menu-main-menu-new" className="menu">
            {menu.map(menuItem => {
              const select = () => setActiveMega(menuItem.databaseId)

              return (
                <li
                  key={menuItem.id}
                  className={`menu-item ${menuItem.cssClasses}`}
                  onMouseEnter={select}
                  onFocus={select}
                  onMouseLeave={() => clearActiveMega(true)}
                  role="presentation"
                >
                  <WPMenuItemLink {...menuItem} partiallyActive={true}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: menuItem.label,
                      }}
                    />
                    {menuItem.cssClasses.indexOf("has-mega-menu") !== -1 && (
                      <svg className='down-arrow' xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M0.816608 0C0.0861388 0 -0.279096 0.863281 0.252154 1.36133L4.20333 5.3125C4.50215 5.64453 5.0002 5.64453 5.33223 5.3125L9.2834 1.36133C9.78145 0.863281 9.41622 0 8.71895 0H0.816608Z" fill="#981C48"/>
                      </svg>
                    )}
                  </WPMenuItemLink>
                </li>
              )
            })}
          </ul>
        </nav>
      )}

      {servicesMenu && (
        <ServicesMenu
          isActive={activeMega + "" === servicesMenu.menuItem}
          menuItems={servicesMenu.menuRepeater}
          onMouseEnter={() => setActiveMega(servicesMenu.menuItem)}
          onMouseLeave={() => clearActiveMega(true)}
          loadIcons={hovered}
        />
      )}

      {ourWorkMenu && (
        <MegaMenu megaID="our_work_mega"
          isActive={activeMega + "" === ourWorkMenu.menuItem}
          menuItems={ourWorkMenu.menuRepeater}
          onMouseEnter={() => setActiveMega(ourWorkMenu.menuItem)}
          onMouseLeave={() => clearActiveMega(true)}
          loadIcons={hovered}
        />
      )}

      {aboutMenu && (
        <AboutMenu
          isActive={activeMega + "" === aboutMenu.menuItem}
          menuItems={aboutMenu.menuRepeater}
          onMouseEnter={() => setActiveMega(aboutMenu.menuItem)}
          onMouseLeave={() => clearActiveMega(true)}
          loadIcons={hovered}
        />
      )}

      <HeaderCTA className="header-cta">
        {generalOptions.phone && (
          <Button
            href={"tel:" + generalOptions.phone}
            className={generalOptions.callTrackingClass}
            color="clear"
            size="small"
          >
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                 data-icon="phone" className="svg-inline--fa fa-phone fa-w-16" role="img" viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
            </svg> {generalOptions.phone}
          </Button>
        )}
        <Button color="pink" size="small" to="/contact/">
          Get in touch
        </Button>

      </HeaderCTA>
    </div>
  )
}

DesktopHeader.propTypes = {}
