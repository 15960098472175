import React, { useEffect } from "react"
import styled from "styled-components"
import { render } from "storyblok-rich-text-react-renderer"

import { storyblokEditable } from "gatsby-source-storyblok"
import Spacer from "@components/ui/Spacer"
import Heading from "@components/ui/Heading"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Prose from "@components/ui/prose"
import Text from "@components/ui/text/text"
import sbRenderOptions from "@configs/sbRenderOptions"
import Helmet from "react-helmet"

const ClutchStyled = styled.section`
  .clutch-widget {
    iframe {
    }
  }
`

const Clutch = ({ blok }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (window?.CLUTCHCO) {
        window.CLUTCHCO.Init()
      }
    }
  }, [])

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://widget.clutch.co/static/js/widget.js"
          defer
        />
      </Helmet>
      <ClutchStyled
        {...storyblokEditable(blok)}
        className="container"
        key={blok._uid}
      >
        <Spacer>
          <div className="content-wrap">
            <div
              className="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="12"
              data-height="375"
              data-nofollow="true"
              data-expandifr="true"
              data-scale="100"
              data-scale="100"
              data-reviews="1995114,1964364,1895100,1893098,1820599,1805038,1803757,1803145,1802360"
              data-clutchcompany-id="308028"
            ></div>
          </div>
        </Spacer>
      </ClutchStyled>
    </>
  )
}
export default Clutch
