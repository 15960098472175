import React from "react"
import PropTypes from "prop-types"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import { Link } from "gatsby"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { storyblokEditable } from "gatsby-source-storyblok"
import Heading from "@components/ui/Heading"
import Text from "@components/ui/text/text"

const ServiceStyled = styled.div`
  padding: 20px;
  border: 1px solid #e2e2e2;
  background-color: var(--color-bg);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transition: all 0.25s;
  display: flex;
  flex-direction: column;
  flex: 1;

  .indic {
    margin-top: auto;
    text-align: right;
    color: var(--color-primary);
  }

  .head-wrap {
    display: flex;
    min-height: 58px;
  }

  .image {
    filter: grayscale(100%);
    margin-right: -11px;
    height: 58px;
    display: flex;
    align-items: center;
    opacity: 0.5;
    flex: 0 0 58px;
    transition: 0.5s all;

    img {
      max-height: 100%;
    }
  }

  .title {
    text-align: left;
    flex: 1;
    margin-bottom: 0;
  }

  .content {
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    font-weight: 300;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.05);
    border-color: #e6a2634d;

    .image {
      filter: grayscale(0%);
      opacity: 1;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`

const Service = ({ blok }) => {
  const { icon, title, short_description, page_link } = blok

  return (
    <ServiceStyled {...storyblokEditable(blok)} key={blok._uid}>
      <Link to={page_link.url}>
        <div className="head-wrap">
          <div className="title">
            <Heading variant="h4" node="h4" decorated>
              {`${title}.`}
            </Heading>
          </div>
          {icon?.filename && (
            <div className="image">
              <img src={icon.filename} alt={icon.alt} />
            </div>
          )}
        </div>
        <div className="content">
          <Text weight="300" size="xsmall" colour="gray">
            {short_description}
          </Text>
        </div>
        <div className="indic text-right">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            className="svg-inline--fa fa-arrow-right fa-w-14 "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
            />
          </svg>
        </div>
      </Link>
    </ServiceStyled>
  )
}

Service.propTypes = {}

export default Service
