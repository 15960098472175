import React from "react"
import styled from "styled-components"
import { render } from "storyblok-rich-text-react-renderer"

import { storyblokEditable } from "gatsby-source-storyblok"
import Spacer from "@components/ui/Spacer"
import Heading from "@components/ui/Heading"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Prose from "@components/ui/prose"
import Text from "@components/ui/text/text"
import sbRenderOptions from "@configs/sbRenderOptions"

const OurClientsStyled = styled.section`
  background-color: var(--color-bg);

  .title-wrap {
    margin-bottom: 45px;
  }

  .content-wrap {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      grid-template-columns: 370px 1fr;
      gap: 57px;
    }
  }

  .client-logos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 120px));
    gap: 38px;

    div {
      display: grid;
      align-items: center;
      justify-content: center;
    }
  }
`

const OurClients = ({ blok }) => {
  return (
    <>
      <OurClientsStyled
        {...storyblokEditable(blok)}
        className="container"
        key={blok._uid}
      >
        <Spacer y="xl">
          <div className="content-wrap">
            <div>
              <Heading node="h3" variant="h3" decorated>
                {blok.title}
              </Heading>
            </div>
            <div className="client-logos">
              {blok?.client_logos.map(clientImage => {
                return (
                  <div>
                    <img src={clientImage.filename} alt={clientImage.alt} />
                  </div>
                )
              })}
            </div>
          </div>
        </Spacer>
      </OurClientsStyled>
    </>
  )
}
export default OurClients
