import React from "react"
import useFooterDetails from "./utils/use-footer-details"
import PropTypes from "prop-types"
import FooterMenu from "./ui/menu/FooterMenu"
import CopyRightCol from "./sections/footer/CopyRightCol"
import TrustPilotWidget from "./sections/footer/TrustPilot"
import FooterProjects from "./sections/footer/FooterProjects"
import styled from "styled-components"

import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"

const FooterStyle = styled.footer`
  background-color: var(--color-primary-darkened);

  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 55px 15px 100px 15px;

    .inner {
      display: flex;
    }
  }

  .trustpilot-widget {
    margin-bottom: 25px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-bottom: 0;
    }
  }

  .inner {
    &--bottom {
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &--top {
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 35px;
      align-items: center;
      text-align: center;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        flex-direction: row;
        align-items: flex-start;
        text-align: initial;
      }

      .footer-col {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        order: 2;

        ${breakpointsMedia(breakpoints.tabletWide)} {
          order: unset;
        }

        &.logo-col {
          order: 1;

          ${breakpointsMedia(breakpoints.tabletWide)} {
            order: unset;
          }
        }
      }
    }

    &--middle {
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 25px;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        flex-direction: row;
      }
    }
  }

  .footer-col {
    &.logo-col {
      ${breakpointsMedia(breakpoints.tabletWide)} {
        align-items: flex-end;
      }
    }

    .footer-copyright {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 27px;
      text-align: right;
      color: var(--color-text-light);
    }

    .logo {
      svg {
        object-fit: contain;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .footer-menu {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      a {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 28px;
        color: var(--color-text-light);
        font-weight: 300;
      }
    }

    &:not(.footer_bottom) {
      li {
        &:nth-of-type(1) {
          width: auto;
          height: auto;
          margin-bottom: 20px;
        }
      }
    }

    &.footer_bottom {
      ul {
        display: flex;
        align-items: flex-start;
        //justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;

        li {
          margin-right: 15px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      a {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 27px;
      }
    }
  }

  .logo {
    max-width: 135px;
    max-height: 75px;

    .logo-group {
      path,
      rect {
        fill: #fff;
        stroke: #fff;
      }
    }
  }

  .footer-projects {
    display: flex;
    flex-direction: row;

    max-width: 350px;
    min-width: unset;
    margin-bottom: 30px;
    align-items: flex-end;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      min-width: 440px;
      align-items: center;
    }

    h3 {
      font-size: 23px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 36px;
      margin: 0;
      margin-right: 15px;
    }

    .footer-project + .footer-project {
      margin-left: 15px;
      padding-left: 15px;
      border-left: 1.5px solid #ffffff;
    }

    .footer-project {
      display: flex;
      align-items: center;
      flex-direction: row;

      a {
        height: 25px;
        max-width: 80px;

        ${breakpointsMedia(breakpoints.tabletWide)} {
          height: 30px;
          max-width: 130px;
        }
      }

      img {
        width: 100px;
        max-height: 100%;
      }

      &:last-of-type {
        &:after {
          content: unset;
        }
      }
    }
  }

  .social-media-links {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      justify-content: flex-end;
      margin-bottom: 0;
    }

    a {
      color: #fff;
      font-size: 19px;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      margin: 0 15px;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        margin-left: 25px;
      }
    }
  }
`

const Footer = ({ siteTitle, socialLinks }) => {
  const {
    trainingMenu,
    resourcesMenu,
    servicesMenu,
    bottomMenu,
    wp: { mainFooter },
  } = useFooterDetails()

  const footerMenus = {
    serviceMenuItems: servicesMenu.nodes[0]?.menuItems?.nodes,
    trainingMenuItems: trainingMenu.nodes[0]?.menuItems?.nodes,
    resourcesMenuItems: resourcesMenu.nodes[0]?.menuItems?.nodes,
    bottomMenuItems: bottomMenu.nodes[0]?.menuItems?.nodes,
  }

  const projects = mainFooter?.acfFooter?.ourProjects?.ourProjectsRepeater

  return (
    <FooterStyle id="main_footer" className="footer">
      <div className="container">
        <div className="inner inner--top">
          {footerMenus.serviceMenuItems && (
            <div className="footer-col">
              <FooterMenu
                menuName={"services"}
                menuItems={footerMenus.serviceMenuItems}
              />
            </div>
          )}
          <div className="footer-col" />
          {footerMenus.resourcesMenuItems && (
            <div className="footer-col">
              <FooterMenu
                menuName={"resources"}
                menuItems={footerMenus.resourcesMenuItems}
              />
            </div>
          )}
          <CopyRightCol siteTitle={siteTitle} socialLinks={socialLinks} />
        </div>

        <div className="inner inner--middle">
          <TrustPilotWidget />

          {projects && <FooterProjects projects={projects} />}
        </div>

        {footerMenus.bottomMenuItems && (
          <div className="inner inner--bottom">
            <FooterMenu
              menuName={"bottom"}
              menuItems={footerMenus.bottomMenuItems}
            />
          </div>
        )}
      </div>
    </FooterStyle>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
  socialLinks: PropTypes.array,
}

export default Footer
