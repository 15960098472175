import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image";
import SVG from "react-inlinesvg"

const CustomGatsbyImage = ({
  className,
  extension,
  childImageSharp,
  publicURL,
  importSVG,
  loading,
  ...rest
}) => {
  const img = (
    <img className={className} src={publicURL} loading={loading} alt="" />
  )

  if (extension === "svg" && importSVG) {
    return (
      <SVG className={className} src={publicURL} loading={loading}>
        {img}
      </SVG>
    )
  } else if (childImageSharp?.gatsbyImageData) {
    return (
      <GatsbyImage
        image={childImageSharp.gatsbyImageData}
        {...rest}
        loading={loading}
        className={className} />
    );
  }

  return img
}

CustomGatsbyImage.propTypes = {
  extension: PropTypes.string,
  childImageSharp: PropTypes.object,
  importSVG: PropTypes.bool,
  publicURL: PropTypes.string,
}
CustomGatsbyImage.defaultProps = {
  importSVG: false,
  loading: `lazy`,
}

export default CustomGatsbyImage
