import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"
import { helmetJsonLdProp } from "react-schemaorg"
import Helmet from "react-helmet"
import useSiteMetadata from "@components/utils/use-site-metadata"

import styled from "styled-components"
import { FontFamily } from "@styles/variables/FontFamily"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"

const SpacerStyled = styled.div`
  display: block;

  &.x-sm {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.x-md {
    padding-left: 50px;
    padding-right: 50px;
  }

  &.x-lg {
    padding-left: 70px;
    padding-right: 70px;
  }
  &.x-xl {
    padding-left: 100px;
    padding-right: 100px;
  }

  &.y-sm {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &.y-md {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &.y-lg {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  &.y-xl {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`

const Spacer = ({ x, y = "md", children }) => {
  return (
    <SpacerStyled className={classNames(x && `x-${x}`, y && `y-${y}`)}>
      {children}
    </SpacerStyled>
  )
}

Spacer.propTypes = {
  x: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  y: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
}

export default Spacer
