import { useStaticQuery, graphql } from "gatsby"

const useWpSiteDetails = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          schema {
            acfSiteSchema {
              schemaTelephone
              schemaEmail
              schemaAggregateRating {
                bestRating
                ratingValue
                reviewCount
                worstRating
              }
            }
          }
          banners {
            acfSimpleBanner {
              simpleBannerContent {
                content
                link {
                  url
                  title
                  target
                }
              }
            }
          }
          social {
            social {
              socialLinks {
                link
                label
                iconType
              }
            }
          }
        }
      }
    `
  )
  return wp
}

export default useWpSiteDetails
