import React from "react"
import PropTypes from "prop-types"
import Heading from "@components/ui/Heading"

const FooterProjects = ({ projects }) => {
  return (
    <div className="footer-projects">
      <Heading node="h3" color="white">
        <span>Our Projects</span>
      </Heading>
      {projects.map((project, key) => {
        const fluid = project.logo?.localFile?.publicURL
        return (
          <div key={key} className="footer-project">
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              {fluid && (
                <img src={fluid} alt={"project logo"} loading={"lazy"} />
              )}
            </a>
          </div>
        )
      })}
    </div>
  )
}

FooterProjects.propTypes = {
  projects: PropTypes.array.isRequired,
}

export default FooterProjects
