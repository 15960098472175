import { createGlobalStyle } from "styled-components"
import Colors from "./variables/Colors"
import { darken } from "polished"

const GlobalStyles = createGlobalStyle`
:root{
    --color-text: #000000;
    --color-text-light: ${Colors.white};
    --color-text-light-off: ${Colors.white};
    
    --color-bg: ${Colors.white};
    --color-bg-shade: ${Colors.offWhite};
    --color-bg-light-off: ${Colors.offWhite};
    
    --color-primary: ${Colors.cardinal};
    --color-primary-darkened: ${Colors.faluRed};
    
    --color-secondary: ${Colors.lightningYellow};
    --color-secondary-darkened: ${darken(0.1, Colors.lightningYellow)};
    
    --color-tertiary: ${Colors.goldenYellow};
    --color-tertiary-darkened: ${Colors.gamboge};
    
    --color-error:#ed143d;
    --color-success:#1e90ff;

  --simple-banner-height: 50px;
}
`

export default GlobalStyles
