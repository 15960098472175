import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"
import PDLogoColor from "../../../assets/images/svg/logo-2.inline.svg"
import styled from "styled-components"
import { breakpointsMedia } from "@styles/variables/Breakpoint"

const MainHeaderStyle = styled.header`
  position: fixed;
  z-index: 999;
  width: 100%;
  max-height: 100px;
  top: 0;
  left: 0;
  transition: all 0.25s linear;

  &:before {
    content: "";
    background: white;
    transition: all 0.25s linear;
    box-shadow: 0 5px 12px -2px rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    transform: translateY(-106%);
    opacity: 0;
  }

  .bg-triangles &:not(.nav-bar-scrolling):before {
    background: transparent;
  }
  

  .container {
    width: 100%;
    max-width: 1500px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    &:before,
    &:after {
      content: none;
    }

    .logo-container {
      height: 55px;
      width: 101px;
      transition: all 0.5s;

      a {
        height: 100%;
        width: 100%;
      }

      svg {
        display: block;
        max-width: 100%;
        height: 100%;

        .logo-group * {
          transition: all 0.25s;
        }
      }
    }
  }

  &:before {
    box-shadow: 0 5px 12px -2px rgba(0, 0, 0, 0.05);
  }

  .container {
    padding: 10px 20px;
  }

  .main-nav {
    .menu {
      .menu-item {
        a {
          color: var(--color-text-light);

          &.page-active > span {
            font-weight: bold;
          }
        }
      }
    }
  }

  body &:not(.nav-bar-scrolling) {
    &:before {
      box-shadow: none;
    }
  }

  &.nav-bar-scrolling {
    z-index: 10000;

    .logo-container {
      height: 40px;
      width: 101px;
    }
  }

  &:before {
    opacity: 1;
    transform: translateY(0);
  }

  .main-nav .menu .menu-item a {
    color: var(--color-text);
  }
  
`

const HeaderWrap = ({ children, isScroll }) => {
  const isSSR = typeof window === "undefined"

  return (
    <MainHeaderStyle
      id="main-header"
      className={classNames("main-header", {
        "nav-bar-scrolling": isScroll,
        loaded: !isSSR
      })}
    >
      <div className="container">
        <div id="PD-logo" className="logo-container">
          <Link to="/">
            <PDLogoColor />
          </Link>
        </div>
        {children}
      </div>
    </MainHeaderStyle>
  )
}

HeaderWrap.propTypes = {
  isScroll: PropTypes.bool
}

HeaderWrap.defaultProps = {
  isScroll: false
}

export default HeaderWrap
