import React from "react"
import PropTypes from "prop-types"
import MegaMenu from "./MegaMenu"
import { acfPageLink } from "@components/utils/shapes"

const AboutMenu = props => {
  return <MegaMenu megaID="about" {...props} />
}

AboutMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      linkIcon: PropTypes.object,
      pageLink: acfPageLink,
      subMenu: PropTypes.array,
    })
  ),
}

export default AboutMenu
