import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "hamburgers/dist/hamburgers.min.css"

export const MobileButton = ({ onClick, mobileMenuOpen }) => {
  return (
    <div
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
      id="mobile-overlay-button"
      className={classNames("hamburger hamburger--elastic", {
        "is-active": mobileMenuOpen,
      })}
    >
      <div className="hamburger-box">
        <div className="hamburger-inner" />
      </div>
    </div>
  )
}

MobileButton.propTypes = {
  onClick: PropTypes.func,
  mobileMenuOpen: PropTypes.bool,
}
