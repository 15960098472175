import React from "react"
import { string_to_slug } from "@components/utils/helpers"
import Button from "@components/ui/buttons/Button"
import WPMenuItemLink from "./WPMenuItemLink"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import Heading from "@components/ui/Heading"

const MegaSideChildMenus = ({
  menuItems,
  hasSubMenuTitle,
  activeSubMenu,
  loadIcons,
}) => {
  return menuItems
    .filter(menuItemData => menuItemData.subMenu)
    .map((menuItemData, key) => {
      const id = string_to_slug(menuItemData.pageLink.title)
      return (
        <div
          key={key}
          className={
            "child-menu-container" +
            (activeSubMenu === id ? " child-menu-active" : "")
          }
        >
          <div className="child-menu-inner">
            {hasSubMenuTitle && (
              <div className="top-row">
                <Heading node="h3" className="services-title dark">
                  {menuItemData.pageLink.title}
                </Heading>
                <Button color="orange" to={menuItemData.pageLink.url}>
                  <span>View page</span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="angle-right"
                    className="svg-inline--fa fa-angle-right fa-w-8"
                    role="img"
                    viewBox="0 0 256 512"
                  >
                    <path
                      fill="currentColor"
                      d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                    />
                  </svg>
                </Button>
              </div>
            )}
            <nav className="child-menu">
              {menuItemData.subMenu?.map((subMenu, subKey) => {
                return (
                  <WPMenuItemLink
                    key={subKey}
                    className="child-menu-link"
                    url={subMenu.subMenuPageLink?.url}
                  >
                    {loadIcons && subMenu.subMenuPageIcon && (
                      <i className="menu-icon">
                        <CustomGatsbyImage
                          {...subMenu.subMenuPageIcon.localFile}
                        />
                      </i>
                    )}
                    <span className="">{subMenu.subMenuPageLink?.title}</span>
                  </WPMenuItemLink>
                )
              })}
            </nav>
          </div>
        </div>
      )
    })
}

export default MegaSideChildMenus
