import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import classnames from "classnames"
import useSiteMetadata from "./utils/use-site-metadata"
import useWpSiteDetails from "./utils/use-wp-site-details"
import isTouchDevice from "is-touch-device"
import { SiteCookieConsent } from "./SiteCookieConsent"
import Fonts from "@styles/PreloadFonts"
import { isChromeLighthouse } from "./utils/helpers"

import "normalize.css"
import "../scss/style.scss"
import GlobalStyles from "@styles/GlobalStyles"

import { SiteBanner } from "./SiteBanner"
import { SiteSchema } from "./SiteSchema"
import Footer from "./Footer"
import { Header } from "./Header"
import useUTM from "./utils/useUTM"
import { storyblokInit, apiPlugin } from "gatsby-source-storyblok"
import SimpleHero from "@components/bloks/sections/SimpleHero"
import IntroSplit from "@components/bloks/sections/IntroSplit"
import Services from "@components/bloks/sections/Services"
import Service from "@components/bloks/modules/Service"
import OurClients from "@components/bloks/sections/OurClients"
import Clutch from "@components/bloks/sections/Clutch"
import CaseStudy from "@components/bloks/modules/CaseStudy"
import LatestWork from "@components/bloks/sections/LatestWork"

storyblokInit({
  accessToken: process.env.GATSBY_STORYBLOK_PREVIEW,
  use: [apiPlugin],
  components: {
    simple_hero: SimpleHero,
    introduction_split: IntroSplit,
    our_services: Services,
    service_module: Service,
    our_clients: OurClients,
    clutch: Clutch,
    latest_work: LatestWork,
    case_study_module: CaseStudy,
  },
})

const Layout = ({ children }) => {
  useUTM()

  const data = useSiteMetadata()
  const wpData = useWpSiteDetails()
  const preloadFonts = Object.values(Fonts)
  const isTouch = isTouchDevice()
  const isSSR = typeof window === "undefined"

  const { social, banners, schema } = wpData
  const { simpleBannerContent } = banners?.acfSimpleBanner

  const classes = classnames(isTouch ? "touch" : "no-touch", {
    "has-sb": simpleBannerContent?.content,
    cl: !isSSR,
  })

  const { localSchema, businessSchema } = SiteSchema(schema?.acfSiteSchema)
  const hideReactDevTools = process.env.GATSBY_REACT_DEVTOOLS === "false"

  return (
    <>
      <Helmet
        htmlAttributes={{
          class: classes,
        }}
      >
        {localSchema && (
          <script type="application/ld+json">{localSchema}</script>
        )}
        {businessSchema && (
          <script type="application/ld+json">{businessSchema}</script>
        )}

        {preloadFonts &&
          preloadFonts.map((url, key) => {
            return (
              <link
                key={key}
                rel="preload"
                as="font"
                href={url}
                type={`font/${url.split(".").pop()}`}
                crossOrigin={`anonymous`}
              />
            )
          })}

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />

        {hideReactDevTools && (
          <script>{`if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function() {}}`}</script>
        )}
      </Helmet>
      <GlobalStyles />

      {simpleBannerContent?.content && <SiteBanner {...simpleBannerContent} />}

      <Header />

      <main>
        <div id={`main_wrapper`}>{children}</div>
      </main>

      <Footer
        siteTitle={data.title}
        socialLinks={social?.social?.socialLinks}
      />

      {!isChromeLighthouse() && <SiteCookieConsent siteURL={data.siteUrl} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
