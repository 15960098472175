import React, { useEffect, useState } from "react"
import { useShortHead } from "./utils/useShortHead"
import { useSmallScreen } from "./utils/useSmallScreen"
import HeaderWrap from "./sections/header/HeaderWrap"
import MobileMenu from "./sections/header/MobileMenu"
import { globalHistory } from "@gatsbyjs/reach-router"
import { MobileButton } from "./ui/buttons/MobileButton"
import { DesktopHeader } from "./sections/header/DesktopHeader"

//TODO: might be worth using a store instead of passing down
export const Header = props => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const shortHead = useShortHead()
  const isSmallScreen = useSmallScreen()

  const onCloseMobileMenu = () => {
    setMobileMenuOpen(false)
  }


  //on mobile menu open/close
  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add("mobile-menu--open")
    } else {
      document.body.classList.remove("mobile-menu--open")
    }

    return () => {
      document.body.classList.remove("mobile-menu--open")
    }
  }, [mobileMenuOpen])

  //close menu on page change
  useEffect(() => {
    const unListen = globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        onCloseMobileMenu()
      }
    })

    return () => {
      unListen()
    }
  }, [])

  return (
    <>
      <HeaderWrap isScroll={shortHead}>
        <DesktopHeader />
        <MobileButton
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          mobileMenuOpen={mobileMenuOpen}
        />
      </HeaderWrap>

      {isSmallScreen && (
        <MobileMenu isOpen={mobileMenuOpen} onClick={onCloseMobileMenu} />
      )}
    </>
  )
}

Header.propTypes = {}

Header.defaultProps = {}
