import styled from "styled-components"

export const HeaderCTA = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;

  @include mq(desktop, max) {
    margin: 0 0 0 20px;
  }
  @include mq(desktop-sm, max) {
    margin: 0 0 0 15px;
  }
  margin: 0 0 0 40px;

  a {
    margin: 0 4px 0 4px;
    height: 50px;
    min-height: unset;
    //min-width: 135px;
    padding: 14px;

    @include mq(desktop-bs, max) {
      font-size: 15px;
    }

    &:nth-of-type(even) {
      margin: 0 0 0 4px;
    }
  }

  svg {
    margin-right: 10px;
  }
  
  &:before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: rgba(0,0,0,0.3);
  }
`
