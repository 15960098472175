import React from "react"
import PropTypes from "prop-types"
import { string_to_slug } from "@components/utils/helpers"
import WPMenuItemLink from "./WPMenuItemLink"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"

const MegaSideMenu = ({ menuItems, setActiveSubMenu, loadIcons }) => {
  return (
    <nav className="side-menu">
      {menuItems.map((menuItemData, key) => {
        const id = string_to_slug(menuItemData.pageLink.title)

        return (
          <WPMenuItemLink
            key={key}
            className="menu-link"
            url={menuItemData.pageLink.url}
            onMouseEnter={() => {
              setActiveSubMenu(id)
            }}
          >
            {loadIcons && menuItemData?.linkIcon?.localFile && (
              <i className="menu-icon">
                {menuItemData.linkIcon?.localFile && (
                  <CustomGatsbyImage
                    importSVG={true}
                    {...menuItemData.linkIcon.localFile}
                  />
                )}
              </i>
            )}
            <span className="">{menuItemData.pageLink.title}</span>
          </WPMenuItemLink>
        )
      })}
    </nav>
  )
}

MegaSideMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
}

export default MegaSideMenu
