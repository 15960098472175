import React from "react"
import PropTypes from "prop-types"
import MegaMenu from "./MegaMenu"
import { acfPageLink } from "@components/utils/shapes"

const ServicesMenu = props => {
  return <MegaMenu megaID="services" hasSubMenuTitle={true} {...props} />
}

ServicesMenu.propTypes = {
  loadIcons: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      linkIcon: PropTypes.object,
      pageLink: acfPageLink,
      subMenu: PropTypes.array,
    })
  ),
}

export default ServicesMenu
