export function timeToRead(content) {
  const wordsPerMinute = 200 // Average case.
  let result
  let strippedString = content.replace(/(<([^>]+)>)/gi, "")
  let textLength = strippedString.split(" ").length // Split by words
  if (textLength > 0) {
    let value = Math.ceil(textLength / wordsPerMinute)
    let min = value > 1 ? "minutes" : "minute"
    result = `${value} ${min} read`
  }
  return result
}

export function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, "") // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes

  return str
}

export function offset(el) {
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export function offsetHeader(el) {
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop

  const scrollTopOffset =
    scrollTop - document.querySelector(`#main-header`).clientHeight

  return { top: rect.top + scrollTopOffset, left: rect.left + scrollLeft }
}

export function scrollTo(el, headerOffset = false) {
  if (!el || typeof el === "undefined") return

  window.scrollTo({
    top: headerOffset ? offsetHeader(el).top : offset(el).top,
    behavior: "smooth",
  })
}

export function getExt(filename) {
  var idx = filename.lastIndexOf(".")
  // handle cases like, .htaccess, filename
  return idx < 1 ? "" : filename.substr(idx + 1)
}

export function wordCount(str) {
  return str.split(" ").length
}

export function wordTruncate(str, no_words, ellipses = true) {
  return str.split(" ").splice(0, no_words).join(" ") + (ellipses ? "..." : "")
}

export function isChromeLighthouse() {
  if (typeof navigator === "undefined") {
    return false
  }

  return (
    navigator.userAgent.indexOf("Speed Insights") !== -1 ||
    navigator.userAgent.indexOf("Chrome-Lighthouse") !== -1
  )
}

//URL must be from same domain
export function downloadFile(filename, url) {
  const element = document.createElement("a")

  element.setAttribute("href", url)
  element.setAttribute("target", "_blank")
  element.setAttribute("rel", "noopener noreferrer")
  element.setAttribute("download", filename)

  element.style.display = "none"
  document.body.appendChild(element)

  element.click()
  document.body.removeChild(element)
}
