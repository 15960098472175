import { graphql, useStaticQuery } from "gatsby"

export const useDesktopHeader = () => {
  return useStaticQuery(graphql`
      query {
          wp {
              siteDetails {
                  generalOptions {
                      callTrackingClass
                      email
                      phone
                  }
              }
          }
          mainMenu: allWpMenu(filter: { locations: { eq: PRIMARY } }) {
              nodes {
                  menuItems {
                      nodes {
                          id
                          url
                          label
                          target
                          path
                          databaseId
                          description
                          cssClasses
                      }
                  }
                  megaMenu: primaryMenu {
                      aboutMenu {
                          menuItem
                          menuRepeater {
                              subMenu {
                                  subMenuPageLink {
                                      target
                                      title
                                      url
                                  }
                                  subMenuPageIcon {
                                      localFile {
                                          extension
                                          publicURL
                                      }
                                  }
                              }
                              pageLink {
                                  target
                                  title
                                  url
                              }
                              linkIcon {
                                  localFile {
                                      extension
                                      publicURL
                                  }
                              }
                          }
                      }
                      ourWorkMenu {
                          menuItem
                          menuRepeater {
                              subMenu {
                                  subMenuPageLink {
                                      target
                                      title
                                      url
                                  }
                                  subMenuPageIcon {
                                      localFile {
                                          extension
                                          publicURL
                                      }
                                  }
                              }
                              pageLink {
                                  target
                                  title
                                  url
                              }
                              linkIcon {
                                  localFile {
                                      extension
                                      publicURL
                                  }
                              }
                          }
                      }
                      servicesMenu {
                          menuRepeater {
                              subMenu {
                                  subMenuPageLink {
                                      url
                                      title
                                      target
                                  }
                              }
                              pageLink {
                                  url
                                  title
                                  target
                              }
                              linkIcon {
                                  localFile {
                                      extension
                                      publicURL
                                  }
                              }
                          }
                          menuItem
                      }
                  }
              }
          }
      }
  `)
}
