import { shape, string, bool, arrayOf } from "prop-types"

export const menuItemShape = shape({
  target: string,
  title: string,
  label: string,
  url: string,
})

export const acfPageLink = menuItemShape

export const menuItemArrayShape = arrayOf(menuItemShape)

export const socialLinksShape = arrayOf(
  shape({
    iconType: string,
    label: string,
    link: string,
  })
)

export const breadcrumbsShape = arrayOf(
  shape({
    url: string,
    title: string.isRequired,
    isHighlight: bool,
  })
)
