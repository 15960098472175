import React, { useEffect, useState } from "react"
import WPMenuItemLink from "@components/ui/menu/WPMenuItemLink"
import classNames from "classnames"

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
export const SubMobileMenu = ({ menuItems, isOpen, isLevel2, isMenuOpen }) => {
  const [activeSubMenu, setActiveSubMenu] = useState(null)

  const toggle = key => {
    setActiveSubMenu(activeSubMenu === key ? null : key)
  }

  const Level2 = isLevel2 ? " sub-opened" : ""

  //close all sub menus while sub menu isn't open
  useEffect(() => {
    if (!isOpen) {
      setActiveSubMenu(null)
    }
  }, [isOpen])

  return (
    <ul className={"mobile-submenu" + (isOpen ? " opened " + Level2 : "")}>
      {menuItems.map((menuItem, key) => {
        const isActive = activeSubMenu === key
        const hasChildren = menuItem.childItems?.nodes.length

        const liClasses = classNames({
          "menu-item": true,
          "menu-item-has-children": hasChildren,
          "menu-item-active": isActive,
        })

        return (
          <li key={key} className={liClasses}>
            {hasChildren ? (
              <div className="link open-sub-menu" onClick={() => toggle(key)}>
                <span dangerouslySetInnerHTML={{ __html: menuItem.label }} />
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down"
                     className="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 320 512">
                  <path fill="currentColor"
  d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
                </svg>
              </div>
            ) : (
              <WPMenuItemLink {...menuItem} />
            )}

            {hasChildren ? (
              <>
                <WPMenuItemLink
                  url={menuItem.url}
                  className={classNames("menu-parent-link", {
                    show: isActive,
                  })}
                >
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
                       className="svg-inline--fa fa-arrow-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 448 512">
                    <path fill="currentColor"
                          d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                  </svg>
                </WPMenuItemLink>
                <SubMobileMenu
                  isLevel2={true}
                  isOpen={activeSubMenu === key}
                  menuItems={menuItem.childItems.nodes}
                />
              </>
            ) : (
              ""
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default SubMobileMenu
