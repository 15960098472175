import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { FontFamily } from "@styles/variables/FontFamily"
import DecoratePunctuation from "@components/ui/DecoratePunctuation"

const HeadingStyled = styled.h1`
  font-family: ${FontFamily.Heading};
  font-weight: 700;
  width: auto;
  height: auto;
  margin: 0;

  .line {
    display: block;
    width: 100px;
    height: 5px;
    margin-top: 10px;
    background: var(--color-primary);
  }

  &.color-white {
    color: var(--color-text-light);
  }

  &.color-pink {
    color: var(--color-primary);
  }

  &.weight-100 {
    font-weight: 300;
  }

  &.weight-200 {
    font-weight: 200;
  }

  &.weight-300 {
    font-weight: 300;
  }

  &.weight-400 {
    font-weight: 400;
  }

  &.weight-500 {
    font-weight: 500;
  }

  &.weight-600 {
    font-weight: 600;
  }

  &.weight-700,
  &.weight-bold {
    font-weight: 700;
  }

  &.variant-h1 {
    font-size: 38px;
    line-height: 57px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 58px;
      line-height: 87px;
    }

    .line {
      height: 8px;
    }
  }

  &.variant-h2 {
    font-size: 28px;
    line-height: 42px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 42px;
      line-height: 63px;
    }
  }

  &.variant-h3 {
    font-size: 24px;
    line-height: 36px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 28px;
      line-height: 42px;
    }
  }

  &.variant-h4 {
    font-size: 18px;
    line-height: 27px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 23px;
      line-height: 36px;
    }
  }

  &.variant-h5 {
    font-size: 18px;
    line-height: 27px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      font-size: 20px;
      line-height: 30px;
    }
  }
`

const Heading = ({
  node = "h1",
  variant,
  color = "base",
  align,
  underlined,
  decorated = false,
  weight = "bold",
  children,
  className,
  ...rest
}) => {
  const _children =
    typeof children === "string" && decorated ? (
      <DecoratePunctuation>{children}</DecoratePunctuation>
    ) : (
      children
    )
  const _variant = variant || node

  return (
    <HeadingStyled
      as={node}
      className={classNames(
        "heading",
        underlined && `underline`,
        _variant && `variant-${_variant}`,
        color && `color-${color}`,
        align && `align-${align}`,
        weight && `weight-${weight}`,
        className
      )}
      {...rest}
    >
      {_children}
      {underlined ? <span className="line" /> : null}
    </HeadingStyled>
  )
}

export default Heading

Heading.propTypes = {
  children: PropTypes.any.isRequired,
  node: PropTypes.string,
  underlined: PropTypes.bool,
  variant: PropTypes.string,
  weight: PropTypes.string,
}
