import { graphql, useStaticQuery } from "gatsby"

const useFooterDetails = () => {
  return useStaticQuery(
    graphql`
      fragment MenuItems on WpMenu {
        slug
        id
        databaseId
        name
        menuItems {
          nodes {
            target
            title
            url
            databaseId
            label
          }
        }
      }

      query {
        trainingMenu: allWpMenu(
          filter: { locations: { eq: FOOTER_TRAINING } }
        ) {
          nodes {
            ...MenuItems
          }
        }
        resourcesMenu: allWpMenu(
          filter: { locations: { eq: FOOTER_RESOURCES } }
        ) {
          nodes {
            ...MenuItems
          }
        }
        servicesMenu: allWpMenu(
          filter: { locations: { eq: FOOTER_SERVICES } }
        ) {
          nodes {
            ...MenuItems
          }
        }
        bottomMenu: allWpMenu(filter: { locations: { eq: FOOTER_BOTTOM } }) {
          nodes {
            ...MenuItems
          }
        }
        wp {
          mainFooter {
            acfFooter {
              ourProjects {
                ourProjectsRepeater {
                  link
                  logo {
                    localFile {
                      publicURL
                    }
                  }
                }
                title
              }
            }
          }
        }
      }
    `
  )
}

export default useFooterDetails
