import styled from "styled-components"
import React from "react"
import { Link } from "gatsby"

import * as PropTypes from "prop-types"
import classNames from "classnames"

const StyledButton = styled.button`
  --btn-bg-color: var(--color-primary);
  --btn-bg-hover-color: var(--color-primary-darkened);
  --btn-color: var(--color-text-light);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transition: all 0.25s;
  cursor: pointer;
  background-color: var(--btn-bg-color);
  color: var(--btn-color);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    font-weight: 600;
    text-decoration: none;
    background-color: var(--btn-bg-hover-color);
    color: var(--btn-color);
  }

  &:active {
    color: var(--btn-color);
  }

  &:focus {
    color: var(--btn-color);
  }

  &.variant-button {
    border-radius: 5px;
    border: none;
    white-space: nowrap;
    height: 48px;
    padding: 11px 19px;

    &.size-small {
      margin: 0 4px 0 4px;
      height: 40px;
      min-height: unset;
      padding: 7px 14px;
    }

    &.size-medium {
      height: 48px;
      padding: 11px 19px;
    }

    &.size-large {
      height: 60px;
      padding: 11px 19px;
      min-height: 50px;
    }
  }

  i,
  svg {
    color: var(--btn-color);
  }

  svg:first-child,
  i:first-child {
    margin-right: 10px;
  }

  svg:last-child,
  i:last-child {
    margin-left: 10px;
  }

  &.color-orange {
    --btn-bg-color: var(--color-secondary);
    --btn-bg-hover-color: var(--color-secondary-darkened);
    --btn-color: var(--color-text-light);
  }

  &.color-pink {
    --btn-bg-color: var(--color-primary);
    --btn-bg-hover-color: var(--color-primary-darkened);
    --btn-color: var(--color-text-light);
  }

  &.color-white {
    --btn-bg-color: var(--color-text-light);
    --btn-bg-hover-color: var(--color-text-light);
    --btn-color: var(--color-text);

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

    i {
      color: var(--color-primary);
    }
  }

  &.color-clear {
    --btn-bg-color: transparent;
    --btn-bg-hover-color: transparent;
    --btn-color: var(--color-text);

    i,
    svg {
      color: var(--color-primary);
    }
  }

  &.variant-text-underline {
    --btn-bg-color: transparent;
    --btn-bg-hover-color: transparent;
    --btn-color: black;
    flex-direction: column;
    padding: 0;
    height: auto;
    box-shadow: none;
    font-weight: 600;
    align-items: flex-start;

    &.color-white {
      --btn-color: var(--color-text-light);
    }

    &:after {
      content: "";
      display: block;
      border: 1px solid #9b1d4a;
      width: 100%;
    }

    &:hover {
      &:after {
        animation: border 1s both;
      }
    }
  }

  &.variant-text {
    --btn-bg-color: transparent;
    --btn-bg-hover-color: transparent;
    --btn-color: var(--color-text);
    padding: 0;
    box-shadow: none;
    font-weight: 600;

    i,
    svg.svg-inline--fa {
      color: var(--brand-secondary);
      margin-left: 10px;
    }
  }

  &.variant-text,
  &.variant-text-underline {
    text-decoration: none;

    &.size-small {
      font-size: 16px;
      line-height: 24px;
    }

    &.size-medium {
      font-size: 18px;
      line-height: 27px;
    }

    &.size-large {
      font-size: 21px;
      line-height: 30px;
    }
  }

  @keyframes border {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }
`

export const Button = ({
  as,
  variant = "button",
  color = "pink",
  size = "medium",
  className,
  children,
  ...props
}) => {
  let node = as || (props.href && "a")

  //check if Link props are there
  if (props.to) {
    node = Link
  }

  return (
    <StyledButton
      as={node}
      className={classNames(
        variant && `variant-${variant}`,
        size && `size-${size}`,
        color && `color-${color}`,
        className
      )}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["button", "text", "text-underline"]),
  color: PropTypes.oneOf(["pink", "white", "orange"]),
}

export default Button
