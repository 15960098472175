import React from "react"
import styled from "styled-components"
import { render } from "storyblok-rich-text-react-renderer"

import { storyblokEditable } from "gatsby-source-storyblok"
import Spacer from "@components/ui/Spacer"
import Heading from "@components/ui/Heading"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Prose from "@components/ui/prose"
import Text from "@components/ui/text/text"
import sbRenderOptions from "@configs/sbRenderOptions"

const IntroSplitStyled = styled.section`
  background-color: var(--color-bg);
  .title-wrap {
    margin-bottom: 45px;
  }

  .content-wrap {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      grid-template-columns: 381px 1fr;
      gap: 154px;
    }
  }
`

const IntroSplit = ({ blok }) => {
  return (
    <>
      <IntroSplitStyled
        {...storyblokEditable(blok)}
        className="container"
        key={blok._uid}
      >
        <Spacer>
          <div className="content-wrap">
            <div>
              <Heading node="h3" variant="h3" decorated>
                {blok.main_title}
              </Heading>
            </div>
            <div>
              <Prose>{render(blok.content, sbRenderOptions)}</Prose>
            </div>
          </div>
        </Spacer>
      </IntroSplitStyled>
    </>
  )
}
export default IntroSplit
