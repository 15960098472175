import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Heading from "@components/ui/Heading"

const WPMenuItemLink = ({
  url,
  label,
  children,
  className,
  isHeader,
  ...rest
}) => {
  return url?.search("http") ? (
    <Link
      className={className}
      to={url}
      onMouseEnter={rest.onMouseEnter}
      onMouseLeave={rest.onMouseLeave}
      activeClassName={"page-active"}
      partiallyActive={rest.partiallyActive}
    >
      {isHeader ? (
        <Heading color="white" node="div" variant="h3">
          {children || <span dangerouslySetInnerHTML={{ __html: label }} />}
        </Heading>
      ) : (
        <>{children || <span dangerouslySetInnerHTML={{ __html: label }} />}</>
      )}
    </Link>
  ) : (
    <a
      className={className}
      href={url}
      onMouseEnter={rest.onMouseEnter}
      onMouseLeave={rest.onMouseLeave}
    >
      {children || <span dangerouslySetInnerHTML={{ __html: label }} />}
    </a>
  )
}

WPMenuItemLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export default WPMenuItemLink
