import React from "react"

export const UTM_CAMPAIGN = "utm_campaign"
export const UTM_KEYWORD = "utm_keyword"

const utms = [UTM_CAMPAIGN, UTM_KEYWORD]

function deleteCookie(cname) {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/"
}

function setCookie(cname, cvalue, exdays) {
  if (typeof document === "undefined") {
    return
  }

  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

function getCookie(cname) {
  if (typeof document === "undefined") {
    return ""
  }

  let name = cname + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export function getUTM(name) {
  return getCookie(name)
}

export function clearUTMCookies() {
  for (const utm of utms) {
    deleteCookie(utm)
  }
}

const useUTM = () => {
  React.useEffect(() => {
    if (!window.location.search) return

    const params = window.location.search?.split("?")[1].split("&")

    if (!params) return

    const paramsObject = {}

    for (const param of params) {
      const split = param.split("=")
      if (split && split.length > 1) {
        paramsObject[split[0]] = split[1]
      }
    }

    if (paramsObject?.utm_campaign) {
      setCookie(UTM_CAMPAIGN, paramsObject.utm_campaign, 7)
    }

    if (paramsObject?.utm_keyword) {
      setCookie(UTM_KEYWORD, paramsObject.utm_keyword, 7)
    }
  }, [])
}

export default useUTM
