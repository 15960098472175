import PropTypes from "prop-types"

export function SiteSchema({
  schemaTelephone,
  schemaEmail,
  schemaAggregateRating,
}) {
  const localSchema = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: "Passion Digital",
    url: "https://passion.digital/",
  }

  if (schemaAggregateRating) {
    localSchema.aggregateRating = {
      "@type": "AggregateRating",
      ...schemaAggregateRating,
    }
  }

  const businessSchema = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "Passion Digital",
    url: "https://passion.digital/",
    aggregateRating: {
      "@type": "AggregateRating",
      worstRating: "1",
      bestRating: "10",
      ratingValue: "9.7",
      reviewCount: "99",
    },
    sameAs: [
      "https://www.facebook.com/wearepassiondigital",
      "https://twitter.com/passion_digital",
      "https://plus.google.com/+PassionDigitalCoUk/posts",
      "https://www.linkedin.com/company/passion-digital",
    ],
  }

  if (schemaTelephone) {
    localSchema.telephone = schemaTelephone
    businessSchema.telephone = schemaTelephone
  }

  if (schemaEmail) {
    localSchema.email = schemaEmail
    businessSchema.email = schemaEmail
  }

  return {
    localSchema: JSON.stringify(localSchema),
    businessSchema: JSON.stringify(businessSchema),
  }
}

SiteSchema.propTypes = {
  schemaTelephone: PropTypes.string,
  schemaEmail: PropTypes.string,
  schemaAggregateRating: PropTypes.object,
}
