import React from "react"
import styled from "styled-components"
import { render } from "storyblok-rich-text-react-renderer"

import { StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import Spacer from "@components/ui/Spacer"
import Heading from "@components/ui/Heading"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Prose from "@components/ui/prose"
import Text from "@components/ui/text/text"
import sbRenderOptions from "@configs/sbRenderOptions"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import { Link } from "gatsby"
import Button from "@components/ui/buttons/Button"

const ServicesStyled = styled.section`
  border: 1px solid #e2e2e2;
  background-color: var(--color-bg-light-off);

  .header {
    margin-bottom: 45px;
    display: grid;
    row-gap: 30px;

    ${breakpointsMedia(breakpoints.tabletSmall)} {
      grid-template-columns: 1fr auto;
    }

    div:nth-child(2) {
      display: flex;
      ${breakpointsMedia(breakpoints.tabletSmall)} {
        justify-content: center;
      }
      align-content: center;
    }
  }

  .services {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;

    ${breakpointsMedia(breakpoints.tabletSmall)} {
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }

    ${breakpointsMedia(breakpoints.desktopSM)} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 30px;
    }
  }
`

const Services = ({ blok }) => {
  const components = blok.services.map(blok => (
    <StoryblokComponent blok={blok} key={blok._uid} />
  ))

  return (
    <>
      <ServicesStyled {...storyblokEditable(blok)} key={blok._uid}>
        <div className="container">
          <Spacer>
            <div className="header">
              {blok?.title ? (
                <div>
                  <Heading node="h2" variant="h2" underlined>
                    {blok.title}
                  </Heading>
                </div>
              ) : (
                <div />
              )}
              <div>
                <Button to="/services/" variant="text-underline" size="small">
                  View all services
                </Button>
              </div>
            </div>
            <div className="services">{components}</div>
          </Spacer>
        </div>
      </ServicesStyled>
    </>
  )
}
export default Services
