import React from "react"
import PropTypes from "prop-types"
import Colors from "@styles/variables/Colors"
import CookieConsent from "react-cookie-consent"

export const SiteCookieConsent = ({ siteURL }) => {
  return (
    <CookieConsent
      buttonText="Accept all Cookies"
      buttonStyle={{
        borderRadius: "5px",
        fontSize: "17px",
        fontWeight: "400",
        padding: "13px 15px",
        background: "white",
      }}
      style={{ background: Colors.cardinal, color: "#ffffff" }}
    >
      <span style={{ color: "#ffffff" }}>
        This site uses cookies to improve your browsing experience, perform
        analytics and research, and conduct advertising. To change your
        preferences, see our Cookies &amp; Tracking Notice. Otherwise, closing
        the banner or clicking Accept all Cookies indicates you agree to the use
        of cookies on your device.
      </span>{" "}
      <a
        aria-label="learn more about cookies"
        role="button"
        tabIndex="0"
        className="cc-link"
        href={siteURL + "/cookies/"}
        style={{ color: "#fff", textDecoration: "underline" }}
      >
        Cookies &amp; Privacy Notice
      </a>
    </CookieConsent>
  )
}

SiteCookieConsent.propTypes = {
  siteURL: PropTypes.string.isRequired,
}
