import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styled from "styled-components"
import MegaSideMenu from "./MegaSideMenu"
import MegaSideChildMenus from "./MegaSideChildMenus"

const MegaMenuStyle = styled.div`
  position: absolute;
  top: 73px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
  transform: translateY(10%);

  .nav-bar-scrolling & {
    top: 57px;
  }

  &.mega-menu-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
  }

  .menu-container {
    display: flex;
    flex-direction: row;

    .side-menu {
      position: relative;
      background-color: var(--color-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      z-index: 11;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid var(--color-primary);
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
      }

      .menu-link {
        padding: 15px 20px 15px 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
        text-decoration: none;
        font-weight: 600;

        .menu-icon {
          width: 25px;
          margin-right: 15px;
          flex: 0 0 25px;
          max-height: 25px;

          svg,
          img {
            width: 25px;
            max-height: 25px;
            animation: fadeIn 1s;

            ellipse {
              transition: fill 0.4s ease;

              &.cls-1 {
                fill: var(--color-text-light);
              }
            }

            path {
              &.cls-2 {
                fill: var(--color-text-light);
              }
            }
          }
        }

        &:hover,
        &.active {
          background-color: var(--color-primary);

          svg {
            ellipse {
              &.cls-1 {
                fill: var(--color-secondary);
              }
            }
          }
        }

        span {
          color: var(--color-text-light);
        }
      }
    }
  }

  &.mega-menu-active .child-menu-container.child-menu-active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
  }

  .child-menu-container {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 100%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--color-bg);
    transition: all 0.25s;
    transform: translateX(-10%);
    min-height: 100%;

    .child-menu-inner {
      .top-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-primary);
        padding-bottom: 15px;
        margin-bottom: 20px;

        h3 {
          margin: 0;
        }

        a {
          margin: 0 4px;
          height: 50px;
          min-height: unset;
          min-width: 135px;
          padding: 14px;

          i {
            margin: 0 0 0 5px;
          }
        }
      }

      .child-menu {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-direction: column;
      }

      .child-menu-link {
        padding-right: 25px;
        line-height: 30px;
        margin-bottom: 9px;
      }
    }
  }
`

const MegaMenu = ({
  megaID,
  menuItems,
  hasSubMenuTitle,
  loadIcons,
  ...rest
}) => {
  const [activeSubMenu, setActiveSubMenu] = useState(null)

  return (
    <MegaMenuStyle
      id={megaID}
      className={classNames("mega-menu", { "mega-menu-active": rest.isActive })}
      onMouseEnter={() => {
        rest.isActive && rest.onMouseEnter()
      }}
      onMouseLeave={() => {
        setActiveSubMenu(null)
        rest.isActive && rest.onMouseLeave()
      }}
    >
      <div className="menu-container">
        <MegaSideMenu
          menuItems={menuItems}
          setActiveSubMenu={setActiveSubMenu}
          loadIcons={loadIcons}
        />
        <MegaSideChildMenus
          hasSubMenuTitle={hasSubMenuTitle}
          menuItems={menuItems}
          setActiveSubMenu={setActiveSubMenu}
          activeSubMenu={activeSubMenu}
          loadIcons={loadIcons}
        />
      </div>
    </MegaMenuStyle>
  )
}

MegaMenu.propTypes = {
  loadIcons: PropTypes.bool,
  megaID: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  hasSubMenuTitle: PropTypes.bool,
}

MegaMenu.defaultProp = {
  loadIcons: false,
}

export default MegaMenu
