import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"
import { helmetJsonLdProp } from "react-schemaorg"
import Helmet from "react-helmet"
import useSiteMetadata from "@components/utils/use-site-metadata"

import styled from "styled-components"
import { FontFamily } from "@styles/variables/FontFamily"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
const SpanStyled = styled.span`
  mark {
    padding: 0;
    background-color: transparent;
    color: var(--color-primary);
  }
`

const DecoratePunctuation = ({ children }) => {
  const text = children
    .replaceAll(",", "<mark>,</mark>")
    .replaceAll(".", "<mark>.</mark>")

  return <SpanStyled dangerouslySetInnerHTML={{ __html: text }} />
}
export default DecoratePunctuation
