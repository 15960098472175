import { graphql, useStaticQuery } from "gatsby"
export const useMobileHeader = () => {
  const { mobileMenu } = useStaticQuery(graphql`
    query {
      mobileMenu: allWpMenu(filter: { locations: { eq: MOBILE } }) {
        nodes {
          menuItems {
            nodes {
              id
              url
              label
              target
              path
              databaseId
              description
              cssClasses
              parentId
              childItems {
                nodes {
                  url
                  label
                  childItems {
                    nodes {
                      url
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return mobileMenu.nodes
}
