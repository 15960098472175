import React from "react"
import PropTypes from "prop-types"
import WPMenuItemLink from "./WPMenuItemLink"
import { menuItemArrayShape } from "@components/utils/shapes"

const FooterMenu = ({ menuName, menuItems }) => {
  return (
    <nav className={`footer-menu footer_${menuName}`}>
      <ul id={`menu-footer-${menuName}`}>
        {menuItems.map((menuItem, key) => {
          return (
            <li key={key} className="menu-item">
              <WPMenuItemLink
                {...menuItem}
                isHeader={key === 0 && menuName !== "bottom"}
              />
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

FooterMenu.propTypes = {
  menuName: PropTypes.string.isRequired,
  menuItems: menuItemArrayShape,
}

export default FooterMenu
