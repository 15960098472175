export default {
  cardinal: `#9B1D4A`,
  cerise: `#E8336E`,
  lightningYellow: `#F7A823`,
  white: `#FFFFFF`,
  offWhite: `#F8F7F7`,
  tuna: `#4C4D4E`,
  zambezi: `#5B5B5B`,
  concrete: `#F3F3F3`,
  mandarinOrange: `#9a1d49`,
  faluRed: `#7C173B`,
  goldenYellow: `#FFDD00`,
  gamboge: `#DB9012`,
  whiteSmoke: `#F4F2F2`,
  dimGray: `#706F6F`,
  brandColour: `#9B1D4A`,
}
