import { css } from "styled-components"

export const breakpoints = {
  phone: `400px`,
  phoneWide: `480px`,
  phablet: `560px`,
  tabletSmall: `640px`,
  tablet: `768px`,
  tabletWide: `992px`,
  desktopSM: `1025px`,
  desktopBS: `1100px`,
  desktop: `1248px`,
  desktopWide: `1440px`,
  desktopMax: `1560px`,
}

export const breakpointsMedia = (breakpoint, type = "min") => {
  return css`
    @media (${type}-width: ${breakpoint})
  `
}
