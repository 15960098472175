import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { acfPageLink } from "./utils/shapes"
import {Link} from "gatsby"

const SiteBannerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: var(--color-primary);

  display: flex;
  align-items: center;
  justify-content: center;

  height: var(--simple-banner-height);
  font-size: 12px;
  transition: all 0.25s linear;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    font-size: 1em;
  }

  .container {
    ${breakpointsMedia(breakpoints.tabletWide, "max")} {
      padding: 0 10px;
    }
  }

  .columns {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    color: var(--color-text-light);
    font-weight: 600;
  }

  a {
    color: var(--color-text-light);
    border: 1px solid var(--color-text-light);
    padding: 5px;
    border-radius: 2px;
    opacity: 1;
    transition: opacity 200ms ease;
    text-decoration: none;
    white-space: nowrap;

    &:hover,
    &:focus {
      color: var(--color-text-light);
      opacity: 0.75;
    }
  }

  ~ header#main-header {
    top: var(--simple-banner-height);

    &.nav-bar-scrolling {
      top: 0;
    }
  }

  + header#main-header + #mobile_overlay_menu_container {
    padding-top: 152px;
  }

  .short--head & {
    transform: translateY(-100%);
  }
`

export const SiteBanner = ({ content, link }) => {
  return (
    <SiteBannerStyled id="site_banner">
      <div className="container">
        <div className="columns">
          <div className="column">
            <p>{content}</p>
          </div>
          <div className="column">
            <Link
              to={link.url}
              dangerouslySetInnerHTML={{ __html: link.title }}
              target='_blank'
            />
          </div>
        </div>
      </div>
    </SiteBannerStyled>
  )
}

SiteBanner.propTypes = {
  content: PropTypes.string,
  link: acfPageLink,
}

SiteBanner.defaultProps = {}
