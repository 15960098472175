import React from "react"
import styled from "styled-components"

import { storyblokEditable } from "gatsby-source-storyblok"
import Spacer from "@components/ui/Spacer"
import Heading from "@components/ui/Heading"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import CaseStudy from "@components/bloks/modules/CaseStudy"
import Button from "@components/ui/buttons/Button"

import SwiperCore, { Pagination } from "swiper"
import "swiper/swiper.scss"
import { Swiper, SwiperSlide } from "swiper/react"

SwiperCore.use([Pagination])

const LatestWorkStyled = styled.section`
  background-color: var(--color-bg);

  > div {
    display: grid;
    grid-template-areas:
      "title"
      "case"
      "links";
    row-gap: 39px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      grid-template-areas:
        "title title links"
        "case case case";
      row-gap: 56px;
    }
  }

  .head {
    grid-area: title;
  }

  .links-wrap {
    grid-area: links;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: center;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-top: 0;
      justify-content: flex-end;
    }

    strong {
      font-size: 16px;
      line-height: 24px;
      color: var(--color-text);
      margin-right: 10px;
    }

    .sep {
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: "";
        display: inline-block;
        width: 3px;
        height: 3px;
        background-color: var(--color-primary);
        border-radius: 100%;
      }
    }
  }

  .links {
    display: grid;
    grid-auto-flow: column;
    gap: 15px;
  }

  .content-wrap {
    grid-area: case;
    overflow: hidden;
  }

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    .swiper-pagination-bullet {
      background-color: var(--color-primary);
      margin: 0 4px;
      width: 36px;
      height: 7px;
      transition: all 0.15s;

      &:first-child:last-child {
        display: none;
      }

      &-active {
        background: #c4c4c4;
      }
    }
  }
`

const LatestWork = ({ blok }) => {
  const components = blok.case_study_module?.map(blok => {
    return (
      <SwiperSlide>
        <CaseStudy blok={blok} key={blok._uid} />
      </SwiperSlide>
    )
  })

  return (
    <LatestWorkStyled
      {...storyblokEditable(blok)}
      className="container"
      key={blok._uid}
    >
      <Spacer>
        <div className="head">
          <Heading node="h2" variant="h2" underlined>
            {blok.title}
          </Heading>
        </div>
        <div className="links-wrap">
          <strong>View all:</strong>
          <div className="links">
            <Button variant="text-underline" to="/case-studies/" size="small">
              Case studies
            </Button>
            {blok?.cta_link && (
              <>
                <i className="sep" />
                <Button
                  variant="text-underline"
                  to={blok.cta_link.url}
                  size="small"
                >
                  {blok.cta_title}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="content-wrap">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={0}
            breakpoints={{
              900: {
                spaceBetween: 32,
                slidesPerView: 2,
              },
            }}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
              renderBullet: function (index, className) {
                return `<span class="${className}"></span>`
              },
            }}
          >
            {components}
            <div className="swiper-pagination "></div>
          </Swiper>
        </div>
      </Spacer>
    </LatestWorkStyled>
  )
}
export default LatestWork
